<template>
  <section
    class="scroll-area bg-[#F8F7F4] relative overflow-y-visible flex justify-center"
  >
    <div
      class="!overflow-x-hidden flex flex-col gap-6 md:gap-12 justify-start items-center lg:flex-row lg:gap-0 lg:pb-9 lg:items-start lg:w-4/5"
    >
      <img
        src="/assets/images/oneorder_circle.svg"
        alt="Oneorder Circle"
        class="absolute bottom-[-13rem] left-[-16rem] opacity-10 w-[120%] max-w-none sm:left-[-30rem] sm:bottom-[-23rem] sm:w-[100%] lg:w-[80%] lg:bottom-[-35rem] lg:left-[-40rem] rtl:lg:left-[60rem]"
      />
      <div class="w-4/5 flex flex-col gap-4 justify-center lg:gap-6">
        <img
            v-if="!isMenuOpen"
          src="../assets/images/logo.svg"
          alt="Logo"
          class="w-36 mt-9 sm:mt-11 mb-9 sm:w-52 z-[60]"
        />
        <h1
          class="font-bold uppercase text-2xl text-secondary sm:text-5xl lg:mt-28 z-30 lg:w-[70%]"
        >
          <span class="rtl:leading-snug">
            {{ $t("hero.allSupplies") }}
          </span>
          <span class="text-primary whitespace-nowrap">{{
            $t("common.oneorder")
          }}</span>
        </h1>
        <h2 class="text-primary uppercase font-bold sm:text-2xl z-20">
          <div>
            {{ $t("hero.location") }}
          </div>
          <div>
            {{ $t("hero.comingSoon") }}
          </div>
        </h2>
        <p class="text-secondary font-medium z-30 sm:text-2xl lg:w-[45%]">
          {{ $t("hero.body") }}
        </p>
        <p class="text-secondary font-bold sm:text-xl z-50">
          {{ $t("hero.alreadyAMember") }}
          <span
            class="text-primary underline cursor-pointer z-50"
            @click="goToSignIn"
            >{{ $t("hero.signIn") }}</span
          >
        </p>
        <div class="flex justify-between items-center sm:w-[55%] lg:w-[45%]">
          <div class="sm:text-xl sm:w-40 lg:w-44 z-50" @click="goToSignUp">
            <primary-button :text="$t('hero.signUp').toString()" />
          </div>
          <div
            class="flex justify-center items-center gap-1 lg:gap-2 cursor-pointer z-30"
            @click="openModal"
          >
            <lottie-player
              src="assets/lottie/watchButton.json"
              background="transparent"
              speed="1"
              :autoplay="true"
              class="w-12"
              :loop="true"
            ></lottie-player>
            <p
              class="text-sm sm:text-xl text-secondary underline font-semibold"
            >
              {{ $t("hero.watchVideo") }}
            </p>
          </div>
          <Modal
            v-model="showfirstModal"
            wrapper-class="h-screen flex justify-center items-center"
            modal-class="!bg-[transparent] !shadow-none !overflow-hidden !w-[60vw] !h-[80vh] flex flex-col justify-center items-end gap-4 lg:!m-0 lg:!max-w-none "
          >
            <template #titlebar>
              <div class="h-4 flex justify-end items-center cursor-pointer">
                <img
                  src="../assets/images/cross.svg"
                  class="h-4"
                  alt="Cross"
                  @click="closeModal"
                />
              </div>
            </template>
            <template #content>
              <div class="flex justify-center items-center">
                <video class="lg:w-[100vw] lg:h-[60vh]" controls>
                  <source
                    :src="`./assets/videos/oneorder_${$i18n.locale}.mp4`"
                    type="video/mp4"
                  />
                  {{ $t("hero.videoError") }}
                </video>
              </div>
            </template>
          </Modal>
        </div>
      </div>
      <img
        :src="
          isTablet
            ? 'assets/images/header/deliveryBoxesTablet.webp'
            : 'assets/images/header/deliveryBoxes.webp'
        "
        alt="Delivery Boxes"
        class="w-[110%] max-w-none lg:absolute sm:-bottom-9 lg:right-0 lg:object-fill sm:relative sm:right-[20%] sm:-mt-[20%] sm:w-[130%] lg:w-[95%] z-20 rtl:sm:right-[-20%] rtl:lg:right-0"
        :class="{ 'transform-rtl': $i18n.locale === 'ar' }"
      />
    </div>
  </section>
</template>

<script>
import VueModal from "@kouts/vue-modal";
import PrimaryButton from "./PrimaryButton.vue";
export default {
  name: "HeroSection",
  props: {
    isMenuOpen: Boolean,
  },
  components: {
    PrimaryButton,
    Modal: VueModal,
  },
  data() {
    return {
      showfirstModal: false,
      isTablet: false,
    };
  },
  created() {
    const width = window.innerWidth;
    if (width > 640) {
      this.isTablet = true;
    }
  },
  methods: {
    closeModal() {
      this.showfirstModal = false;
    },
    openModal() {
      this.showfirstModal = true;
    },
    goToSignUp() {
      window.location.href = `https://app.oneorder.net${
        this.$i18n.locale === "ar" ? "/ar" : ""
      }/signup`;
    },
    goToSignIn() {
      window.location.href = `https://app.oneorder.net${
        this.$i18n.locale === "ar" ? "/ar" : ""
      }/login`;
    },
  },
};
</script>
<style scoped>
.transform-rtl {
  transform: rotateY(180deg);
}
</style>
